// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---contributing-md": () => import("./../../CONTRIBUTING.md" /* webpackChunkName: "component---contributing-md" */),
  "component---readme-md": () => import("./../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-examples-class-components-mdx": () => import("./../../src/pages/examples/class-components.mdx" /* webpackChunkName: "component---src-pages-examples-class-components-mdx" */),
  "component---src-pages-examples-functional-components-mdx": () => import("./../../src/pages/examples/functional-components.mdx" /* webpackChunkName: "component---src-pages-examples-functional-components-mdx" */),
  "component---src-pages-examples-hocs-mdx": () => import("./../../src/pages/examples/hocs.mdx" /* webpackChunkName: "component---src-pages-examples-hocs-mdx" */),
  "component---src-pages-examples-hooks-mdx": () => import("./../../src/pages/examples/hooks.mdx" /* webpackChunkName: "component---src-pages-examples-hooks-mdx" */),
  "component---src-pages-examples-react-router-mdx": () => import("./../../src/pages/examples/react-router.mdx" /* webpackChunkName: "component---src-pages-examples-react-router-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-installation-create-react-app-mdx": () => import("./../../src/pages/installation/create-react-app.mdx" /* webpackChunkName: "component---src-pages-installation-create-react-app-mdx" */),
  "component---src-pages-installation-gatsby-mdx": () => import("./../../src/pages/installation/gatsby.mdx" /* webpackChunkName: "component---src-pages-installation-gatsby-mdx" */),
  "component---src-pages-installation-zeit-next-js-mdx": () => import("./../../src/pages/installation/zeit-next-js.mdx" /* webpackChunkName: "component---src-pages-installation-zeit-next-js-mdx" */),
  "component---src-pages-typescript-crash-course-mdx": () => import("./../../src/pages/typescript-crash-course.mdx" /* webpackChunkName: "component---src-pages-typescript-crash-course-mdx" */)
}

